import { useEffect, useState } from "react"
import { api } from "../api/api"
import { Toast } from "../utils/Toast"
import { useSelector } from "react-redux"

export function useIntegrationWhatsApp () {
  const company = useSelector(state => state.company.data)
  const [qrCode, setQrCode] = useState('')
  const [messages, setMessages] = useState({
    messageAccepted: '',
    messageSendDelivery: '',
    messageSendReview: ''
  })

  async function checkWpp () {
    try {
      const response = await api.get(`/integration-whatsapp/health`)

      if (response.data === 'Conectado') {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  async function create () {
    Toast.success('Estamos conectando, aguarde uns instantes e peça para gerar o qrCode')
    return await api.post(`/integration-whatsapp/connect`)
      .then(() => {
        return true
      }).catch(async () => {
        await restartIntegration(false)
        return false
      })
  }

  async function getTextMessages () {
    try {
      const response = await api.get('/integration-whatsapp')

      setMessages({
        messageAccepted: response.data[0]?.messageAccepted,
        messageSendDelivery: response.data[0]?.messageSendDelivery,
        messageSendReview: response.data[0]?.messageSendReview
      })
    } catch (error) { }
  }

  async function getQrCode () {
    try {
      const response = await api.get('/integration-whatsapp/qrcode')
      setQrCode(response.data)
      Toast.success("QRCODE gerado com sucesso.")
    } catch (error) {
    }
  }

  async function restartIntegration (msg = true) {
    if (msg) {
      Toast.warning('Estamos desconectando o whatsapp')
    }
    await api.get(`/integration-whatsapp/disconnect`)
  }

  async function sendMessage (phone = '', key, order) {
    try {
      if (phone.length === 11) {
        let [d1, d2, , ...rest] = phone.split('')
        if (rest.length > 9) {
          rest = rest.join('').substring(1).split('')
        }

        phone = `${d1}${d2}${rest.join('')}`
      }

      let message = messages[key]

      let link = ''

      if (company.domain) {
        link = `https://${company.domain}/${order.id}`
      } else {
        link = `${process.env.REACT_APP_CARDAPIO}/${company.link}/${order.id}`
      }

      message = message
        .replace("@NOME_CLIENTE", order.name)
        .replace("@NUMERO_PEDIDO", order.numOrder)
        .replace("@CONTATO_CLIENTE", order.phone)
        .replace("@LINK", link)

      api.post('/integration-whatsapp/send/message', { phone, message: message }).catch(() => { })
    } catch (error) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    if (window.location.pathname === '/' || window.location.pathname === '/register') {
      return
    } else {
      getTextMessages()
    } 
  }, [])

  return { getQrCode, qrCode, sendMessage, restartIntegration, checkWpp, create }
}
